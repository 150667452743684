/* Add your global styles here */

.index-features {
	text-align: center;
	background: linear-gradient(to bottom, #421983 50%, #fff 50%);
	padding: 40px 0 0 0;
}
.index-features-slider {
	position: relative;
	z-index: 2;
	width: 98%;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
	transition: width 0.5s ease-in-out; /* Add the transition property */
}

.index-features__title {
	font-size: 24px;
	margin-bottom: 20px;
}

.index-features__tabs {
	display: flex;
	justify-content: space-around;
	padding: 55px 5px 5px 5px;
	margin-bottom: 20px;
}
.index-features__tab-label {
	margin-top: 26px;
	color: #fff;
	font-weight: bold;
}

.index-features__tab {
	text-align: center;
	background-color: transparent;
	border: none;
	z-index: 2;
	border-radius: 4px;
	text-align: center !important;
	width: 15%;
	margin: auto;
	cursor: pointer;
	transition: background-color 0.3s;
	border-bottom: 5px solid #898d9a;
}
.index-features__tab-head {
	height: 178px;
}
.index-features__tab--active {
	border-bottom: 5px solid #b880ff;
	color: #000;
}

.index-features-item {
	display: none;
}

.index-features-item.active {
	display: block;
}

.index-features-item__inner {
	display: flex;
	padding: 40px 20px;
	justify-content: space-between;
}

.index-features-item__left {
	width: 60%;
	padding-right: 20px;
	text-align: left;
}
.index-features-item__left h4 {
	font-size: 30px;
	line-height: 36px;
	margin-bottom: 24px;
	font-weight: bold;
}

.index-features-item__title ul {
	font-size: 18px;
	margin-bottom: 10px;
	margin-bottom: 24px;
	padding-left: 19px;
	list-style: disc;
	font-weight: lighter;
}
.index-features-item__title li {
	margin-bottom: 15px;
}

.index-features-item__text {
	list-style: disc;
	padding-left: 20px;
}

.index-features-item__img-wrap {
	width: 60%;
}

.index-features-item__img {
	max-width: 100%;
	height: auto;
}

.index-features-item__btn {
	display: inline-block;
	margin-top: 10px;
	padding: 10px 20px;
	background-color: #b880ff;
	color: #fff;
	border: none;
	border-radius: 4px;
	text-decoration: none;
	transition: background-color 0.3s;
}

.index-features-item__btn:hover {
	background-color: #a366f4;
}
/* Add the levitate animation */
@keyframes levitate {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

/* Apply the levitate animation to the active icon */
.index-features__tab--active .index-features__tab-icon {
	animation: levitate 2s ease-in-out infinite;
}
.index-features-content {
	cursor: grab; /* Change cursor to grab */
}
.index-features-content:active {
	cursor: grabbing; /* Change cursor to grabbing when actively swiping */
}
.label-mob {
	display: none;
}
/* Add a new class for the wider slider */
.index-features-slider-expanded {
	width: 90%;
}
@media (max-width: 759px) {
	.index-features__tab-label {
		display: none;
	}
	.index-features-slider-expanded {
		width: 95%;
		box-shadow: none;
	}
	.label-mob .title {
		color: #421983;
		font-size: 30px;
		font-weight: bolder;
	}
	.label-mob {
		display: block;
		text-align: left;
		width: 96%;
		margin: auto auto 40px auto;
	}
	.index-features__tab-head {
		height: 100px;
		position: relative;
		top: 20px;
	}
	.index-features__tabs {
		padding: 0;
	}
	.index-features-item__img-wrap {
		width: 100%;
	}
	.index-features-item__left {
		width: 100%;
	}
	.index-features-item__inner {
		display: block;
		padding: 0;
	}
}
@media (max-width: 452px) {
	.index-features__tab-label {
		display: none;
	}
	.index-features__tab-head {
		height: 80px;
		position: relative;
		top: 20px;
	}
	.index-features__tabs {
		padding: 0;
	}
	.index-features__tab-icon {
		max-width: 35px;
		margin-top: -5px;
	}
}
.fix--bg {
	background-color: #421983;
}
@media (max-width: 340px) {
	.index-features__tab-head {
		height: 70px;
	}
}
@media (max-width: 270px) {
	.index-features__tab-icon {
		max-width: 30px;
	}
}
