/* About.css */

.about-section {
	padding: 50px;
	background-color: #fff; /* Set the initial background color */
	color: #000; /* Set the text color */
	position: relative;
    z-index: 1;
	transition: background-color 0.5s ease; /* Add a smooth transition for background color change */
}

.about-section .text {
}

.about-section h5 {
	padding-top: 20px;
	font-family: sans-serif;
	font-weight: 700;
	font-size: clamp(5.625rem, 4.21875rem + 2.5vw, 8.4375rem);
	line-height: 0.9444;
	letter-spacing: -0.05em;
	margin: clamp(1.875rem, 1.40625rem + 0.833333vw, 2.8125rem) 0px;
}

p {
	font-size: 1.2em;
	line-height: 1.6;
}
.about-icons.about-items {
	display: grid;
	grid-column-gap: 12px;
	grid-row-gap: 12px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 50px;
}
.about-items-s {
	font-size: 2.5em;
	font-weight: 500;
	line-height: 140%;
}

@media screen and (max-width: 479px) {
	.about-items-s {
		font-size: 1.5em;
	}
}

@media screen and (max-width: 767px) {
	.about-items-s {
		font-size: 1.75em;
	}
}

@media screen and (max-width: 991px) {
	.about-items-s {
		font-size: 1.75em;
	}
}

@media screen and (max-width: 991px) {
	.about-icons.about-items {
		grid-column-gap: 2em;
		grid-row-gap: 2em;
		flex-direction: row;
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 767px) {
	.about-icons.about-items {
		grid-column-gap: 4em;
		grid-row-gap: 4em;
		flex-direction: column;
		grid-template-columns: 1fr;
		/* margin-top: 4em; */
	}
}

/* Add more styles as needed */
@media screen and (max-width: 1019px) {
	.about-section h5 {
		font-size: clamp(3.125rem, 2.34375rem + 1.38889vw, 4.6875rem);
		line-height: 0.96;
		margin: clamp(1.125rem, 0.84375rem + 0.5vw, 1.6875rem) 0px;
	}
}
