.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
	
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.header-logo {
	position: relative;
	top: -5px;
}
a {
	text-decoration: none !important;
}
.container {
	max-width: 95% !important;
}
.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.shape-1 {
	position: absolute;
	bottom: 0;
	right: 0;
	/* Define the animation */
	animation: rotate 40s linear infinite;
	/* Add the following line to ensure the rotation point is centered */
	transform-origin: center center;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.home {
	background-color: #fff !important;
	color: #000 !important;
}
.abouts-text_head {
	width: 1000px;
	margin: auto;
	text-align: center;
}
