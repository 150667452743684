.containr {
	width: 400%;
	height: 100vh;
	display: flex;
	flex-wrap: nowrap;
	color: #fff;
	background-color: #101820;
	font-family: Inter, sans-serif;
	text-transform: capitalize !important;
}
section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 0 10px;
}

.panel {
	width: 100%;
	height: 100%;
}
.panel img {
	width: 65%;
}
.heading--270 {
	font-weight: 900;
	width: 100%;
	font-size: 10rem;
	line-height: 95%;
}
.heading--170 {
	font-size: 6rem;
	font-weight: 900;
}
.msmall {
	text-align: center;
	font-size: 3rem;
	font-weight: 900;
}
.hand {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 40% !important;
	animation: moveUpDown 2s infinite alternate;
}
.hand1 {
	position: absolute;
	bottom: 0;
	width: 140px !important;
	animation: moveUpDown 2s infinite alternate;
}
@media screen and (max-width: 991px) {
	.heading--270 {
		font-size: 8rem;
	}
}
@media screen and (max-width: 767px) {
	.heading--270 {
		font-size: 6rem;
	}
	.heading--170 {
		font-size: 4rem;
		font-weight: 900;
	}
	.msmall {
		text-align: center;
		font-size: 2rem;
		font-weight: 900;
	}
}
@media screen and (max-width: 479px) {
	.heading--270 {
		font-size: 4rem;
	}
	.panel img {
		max-width: 100vh;
		width: 40vh;
	}
}
@keyframes flicker {
	0%,
	100% {
		stop-opacity: 0.6;
	}
	25%,
	75% {
		stop-opacity: 0.8;
	}
	50% {
		stop-opacity: 1;
	}
}

.illustrations_image {
	animation: flicker 2s infinite alternate;
}
.axil-header.header-style-4 .axil-mainmenu.axil-sticky.transparent-header {
	background-color: transparent; /* Adjust the alpha value for transparency */
}

@keyframes moveUpDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(5px); /* Adjust the distance as needed */
	}
}

@keyframes rotateHand {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(70deg); /* Adjust the rotation angle as needed */
	}
}
