.footer-area {
	color: #fff;
	background-color: #101820;
}
.addy {
	margin-top: 150px;
	color: #fff !important;
}

.footer-contactus h4 {
	opacity: 0.6;
	margin-bottom: 19px;
}
.footer-contactus h4:hover {
	color: rgb(129, 119, 241);
}
