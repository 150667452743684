/* ContactPage.css */

.contact-container {
	background: #101820;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 90px 0;
}

.contact-card {
	width: 600px;
	padding: 20px;
	border-radius: 8px;
}
.contact-card label {
	color: #fff;
}
.contact-card h2 {
	font-size: 3rem;
	margin: 40px 0;
	font-weight: 500;
}
.contact-card p {
	font-size: 1.25rem;
	line-height: 1.7;
}

.form-group {
	margin-bottom: 15px;
}

label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

input,
textarea {
	font-family: "Segoe UI", "Candara", "Bitstream Vera Sans", "DejaVu Sans",
		"Bitstream Vera Sans", "Trebuchet MS", sans-serif;
	background: #f5f6f7;
	border: none;
	width: 100%;
	color: rgba(0, 0, 0, 0.7);
	font-size: 1rem;
	line-height: 1.2;
	padding: 15px;
}

button {
	background-color: #007bff;
	color: #fff;
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
}



/* Smaller checkbox styling */
input[type="checkbox"] {
	margin-right: 5px;
	width: 14px; /* Adjust the width of the checkbox */
	height: 14px; /* Adjust the height of the checkbox */
}
.submitted-container {
    padding: 190px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
    background: #101820;
    color: #fff;
}

.submitted-card {
	text-align: center;
	padding: 20px;
	border-radius: 8px;
	max-width: 400px;
    margin-top: 90px;
}

.submitted-card h2 {
	font-weight: 900;
    font-size: 2em;
}

.submitted-card p {
	margin: 10px 0;
    opacity: 0.7;
}

.submitted-card a {
	color: #0066cc;
	text-decoration: none;
	font-weight: bold;
	display: block;
	margin-top: 20px;
}

/* Two columns on larger screens */
@media (min-width: 600px) {
	.form-group {
		display: flex;
		flex-wrap: wrap;
	}

	/* Adjust the width of each checkbox item */
	.form-group div {
		width: 50%;
	}
}

/* Responsive adjustments */
@media (max-width: 600px) {
	.contact-card {
		max-width: 100%;
		width: 100%;
	}
	.contact-card h2 {
		font-size: 2.3rem;
	}
	.contact-card p {
		font-size: 1.1rem;
		line-height: 1.7;
	}
}
