/* ProjectList.css */
.project-link {
	touch-action: manipulation;
}

.project-list {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 90%;
	margin: 0 auto;
}

.project-item {
	display: block;
	/* border: 1px solid #ccc; */
	border-radius: 8px;
	overflow: hidden;
}
.pro_p {
	margin-bottom: 50px;
	opacity: 0.5;
}
.project-thumbnail {
	position: relative;
	overflow: hidden;
}

.project-thumbnail img {
	width: 100%; /* Set the width to 100% */
	height: auto;
	display: block;
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* Add styles for your play button */
}

.project-details {
	padding: 10px;
}
.project-details h3 {
	font-size: 5rem;
	color: black;
	font-weight: 900;
}
.ourwork {
	font-size: 3.5rem;
	margin: 10px 0 40px 0;
	font-weight: bolder;
	color: #101820;
	text-align: center;
}
