/* Add your styling for the "Work" component */
.work-container {
	padding: 20px;
	background-color: rgb(16, 24, 32);
    color: #fff;
}
.workb{
    margin-top: 110px;
} 
.work-container h3 {
    color: #fff;
}