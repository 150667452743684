/* General Styles */
.app {
	font-family: Arial, sans-serif;
	
}

.Es-container {
    position: relative;
	display: flex;
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	gap: 20px; 
	flex-wrap: wrap; 
}

.sticky {
    margin-top: 65px;
    font-family:'Segoe UI', Tahoma, Verdana, sans-serif;
    position: sticky;
    top: 20%;
    margin-right: 4px;
    height: 20%; /* This is a percentage of the parent's height */
    border: 1px solid var(--Primary-Lighter-Gray, #EBEDEF) !important;
    padding: 10px;
} 
.sticky h3 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--Primary-Lighter-Gray,#EBEDEF);
}
.sticky ul {
    list-style:none;
    margin-left: -30px;
} 

.sticky a {
    text-decoration: none ;
    margin-top: 20px;
    color: rgb(83, 80, 80);
}
.sticky a:hover {
    color: rgb(158, 122, 122);
}


.toc.fixed {
	position: fixed;
	top: 20px; /* Adjust as needed */
	right: 20px; /* Keep it aligned to the right */
}
.content {
    font-family:'Segoe UI', Tahoma, Verdana, sans-serif;
}
.content h2 {
    margin-top: 55px;
    font-size: 50px;
    font-weight: 900;
}
.content h3 {
    font-size: 30px;
    font-weight: 900;
}
.content p {
    font-size: 18px;
    font-weight: normal;
}
.pricing-options {
    margin: 15px 0 55px 0;
    }

.toc h3 {
	margin-bottom: 20px;
}

.toc ul {
	list-style: none;
	padding: 0;
}

.toc ul li {
	margin-bottom: 10px;
}

.toc ul li a {
	text-decoration: none;
	color: #007bff;
}

.toc ul li a:hover {
	text-decoration: underline;
}

.content {
	width: 70%;
}

.content-section {
	margin-bottom: 40px; /* Space between sections */
}

.content-section h2 {
	margin-bottom: 20px;
}

.content-section p {
	margin-bottom: 20px;
}

.pricing-options {
	display: flex;
	flex-wrap: wrap; /* Wrap items on smaller screens */
	gap: 20px; /* Space between options */
}

.option {
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 100%; /* Full width by default */
	box-sizing: border-box;
}

.option h3 {
	margin-bottom: 10px;
}

.option p {
	margin-bottom: 0;
}
.supp li {
    margin-bottom: 25px;
}

/* Responsive Styles */
@media (max-width: 902px) {
	.Es-container {
		flex-direction: column;
	}

	.sticky {
		width: 100%;
		position: static; /* Static position on small screens */
		margin-bottom: 20px;
	}

	.content {
		width: 100%;
	}
    .content h2 {
        font-size: 30px;
        font-weight: 900;
    }
    .content h3 {
        font-size: 20px;
        font-weight: 900;
    }
    .content p {
        font-size: 16px;
        font-weight: normal;
        text-align: justify;
        word-spacing: 0.1px;
    }
}
