.mini-f {
	background-color: rgb(16, 24, 32);
	color: #fff;
	padding-top: 40px;
	padding-bottom: 80px;
}
.mini-f section {
    position: relative;
	height: 50vh !important;
}
.mini-f section img {
    width: 30% !important;
}
.mini-f section h2 {
	width: 95%;
	text-align: center;
	margin-bottom: 90px;
	font-size: 4rem;
}
.hr {
	border-top: 0.2px solid grey;
    width: 95%;
    padding-bottom: 90px;
}

@media screen and (max-width: 591px) {
	.mini-f section h2 {
		font-size: 2.6rem;
	}
}
