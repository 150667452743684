.service-page {
	background-color: #fff;
	font-family: sans-serif;
}
/* Service.css */
.service-content {
	margin: 90px auto auto auto;
}
.service-page video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	z-index: -1;
	object-position: center;
	object-fit: cover;
}
.service-content h2 {
	font-family: "GT-Haptik-Medium", sans-serif;
	font-size: 2rem;
	font-weight: 600;
	letter-spacing: -1px;
	line-height: 1.14;
}
.service-content ul {
	list-style-type: none;
	/* color: #000; */
	font-weight: bolder;
	margin-top: 35px;
}
.service-content ul li:before {
	content: "\2014";
	position: absolute;
	margin-left: -20px;
}
.service-content li {
	padding-bottom: 30px;
	font-size: large;
}

.service-page .banner-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
}

.service-page .banner-video {
	width: 100%;
	height: auto;
}

.service-page .banner {
	color: white;
	padding: 196px 0;
	min-height: 0;
}
.service-page .tang {
	display: none;
}
.service-page .play {
	display: flex;
}

/* .service-page .banner-image-mobile {
	display: none;
} */

.service-page .banner-image {
	width: 90%;
}

.service-page .banner-text {
	margin-bottom: 30px;
}

.service-page .banner-text h1 {
	position: relative;
	top: 30px;
	top: 50px;
	font-size: 3rem;
	font-weight: 700;
	margin: auto;
	width: 100%;
	text-align: center;
	font-family: Verdana, sans-serif;
}

.service-page .banner-text .text-yellow {
	color: #7ced03;
}

.service-page .service-page .banner-text .text-customer {
	color: rgb(132, 220, 255);
}
.service-page .banner-text .text-health {
	color: #1da3d9;
}
.service-content {
	display: flex;
	flex-direction: column;
}
.image-content img {
	width: 100%;
}
@media (min-width: 968px) {
	.image-content img {
		width: 100% !important;
	}
	.service-content {
		margin: 100px auto auto auto;
	}
	.service-content {
		flex-direction: row;
	}

	.text-content {
		flex: 1;
	}
	.text-content-right {
		padding-right: 40px;
	}
	.text-content-left {
		padding-left: 40px;
	}
	.image-content {
		flex: 1;
		margin-top: 0; /* Reset the margin for desktop */
	}
}
@media screen and (max-width: 968px) {
	.text-content-right {
		padding-right: 40px;
	}
	.text-content-left {
		padding-left: 40px;
	}
}

@media screen and (max-width: 591px) {
	.service-page .banner-text h1 {
		font-size: 2rem;
	}
	.service-content h2 {
		font-size: 21px;
	}
}

@media (min-width: 868px) {
	.service-content {
		flex-direction: row;
	}

	.content-container {
		display: flex;
		flex: 1;
		flex-direction: row-reverse; /* Reverse the order on larger screens */
	}

	.text-content {
		flex: 1;
	}

	.image-content {
		flex: 1;
	}
}
