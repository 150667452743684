.quote-calculator {
    font-family:'Segoe UI', Tahoma, Verdana, sans-serif;
    max-width: 90%;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.quote-calculator .title {
    text-align: center;
    margin: 30px 0 15px 0;
    font-weight: 900;
    font-size: 40px;
}
.quote-calculator .sub-title {
    text-align: center;
    margin-bottom: 60px;
}

.quote-calculator .input-g {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.quote-calculator .input-g label {
    font-size: 13px;
    flex: 1;
    font-weight: bold;
}

.quote-calculator .input-g .slider {
    flex: 2;
    margin: 0 10px;
}

.quote-calculator .input-g .range-value {
    flex: 1;
    text-align: right;
    font-weight: bold;
}

.quote-calculator .button-group {
    text-align: center;
}

.quote-calculator .button-group button {
    display: inline-block;
    font-weight: 900;
    width: 48%;
    padding: 10px;
    margin: 10px 1%;
    border: none;
    border-radius: 4px;
    background-color: #000;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quote-calculator .button-group button:hover {
    background-color: #3a3939;
}

.quote-calculator .price-range {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .quote-calculator {
        max-width: 100%;
        margin: 2px;
        padding: 15px;
    }

    .quote-calculator .title {
        font-size: 25px;
    }

    .quote-calculator .button-group button {
        width: 100%;
        margin: 10px 0;
    }
}

/* Targeting the range input's thumb and track */
input[type="range"] {
    -webkit-appearance: none; /* Remove default appearance */
    appearance: none;
    width: 100%; /* Full width */
    height: 3px; /* Track height */

    outline: none; /* Remove outline */
    opacity: 0.7; /* Opacity for the track */
    transition: opacity .2s; /* Smooth transition */
}

input[type="range"]:hover {
    opacity: 1; /* Full opacity on hover */
}

/* Thumb styling */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default thumb */
    appearance: none;
    width: 20px; /* Thumb width */
    height: 20px; /* Thumb height */
    background: #000; /* Thumb color */
    cursor: pointer; /* Cursor style */
    border-radius: 50%; /* Make thumb circular */
}

input[type="range"]::-moz-range-thumb {
    width: 20px; /* Thumb width */
    height: 20px; /* Thumb height */
    background: #000; /* Thumb color */
    cursor: pointer; /* Cursor style */
    border-radius: 50%; /* Make thumb circular */
}
