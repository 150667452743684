.PD {
	padding-top: 190px;
    background: #101820 !important;
	color: #fff;
}
.head_color {
	background: #101820 !important;
}
.projectD {
	width: 90%;
	margin: auto;
}
.project-section h2,
.project-section h3 {
	font-weight: 900;
	font-size: 3rem;
}
.p-intro p {
	font-size: 30px !important;
	font-weight: normal !important;
	margin: 30px 0;
}
.project-section p {
	font-size: 25px;
	font-weight: lighter;
}
.project-section_visual_img{
	display: flex;
	width: 50%;
}